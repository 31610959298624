export const agrobeelMockData = {
  config: {
    pk: {
      deviceId: 10014,
      type: 9,
      id: 1,
    },
    name: "Coordinador 1",
    modbusDirection: null,
    chanel: 7,
    networkCode: 52,
    suspended: null,
    active: null,
    attempts: null,
    xErrorModem: null,
    modifiedDate: "14-05-2024 13:00:44",
    modified: 3,
    modifiedBy: 2,
    queryDate: "28-05-2024 11:57:58",
    initTime: null,
    vccAnomalyTime: null,
    vccTime: null,
    vccAction: null,
    totalGroups: null,
    tries: null,
    xErrorNo18V: null,
    comunicationTime: 0,
    band: 0,
    modeTx: 16,
    deliveryTime: null,
    externalModulesMAM120: null,
    externalModulesMAR433: null,
    externalModulesME: null,
    xState: 2,
    xType: false,
    xerrorRed: null,
    xerrorReloj: null,
    xerrorFRAM: null,
    xcomunica: null,
    xversion: null,
    xerrorSincr: null,
    xerrorSai: null,
    xtype: null,
  },
  modules: [
    {
      moduleId: 1,
      serialNumber: "0",
      model: "4",
      properties: {},
    },
    {
      moduleId: 2,
      serialNumber: "7208",
      model: "13",
      properties: {},
    },
    {
      moduleId: 3,
      serialNumber: "0",
      model: "9",
      properties: {},
    },
    {
      moduleId: 4,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 5,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 6,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 7,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 8,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 9,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 10,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 11,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 12,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 13,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 14,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 15,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 16,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 17,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 18,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 19,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
    {
      moduleId: 20,
      serialNumber: "0",
      model: "0",
      properties: {},
    },
  ],
};

export const sdi12MockData = {
  config: {},
  modules: [
   
    {
      id: 1,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 2,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 3,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 4,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 5,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 6,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 7,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 8,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 9,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
    {
      id: 10,
      model: "mockModel",
      terrain: "mockTerrain",
      format: "mockFormat",
      density: 12,
      configuration: "MANUAL",
    },
  ],
};
