import {
  A4500AgroBeeLRequest,
  A4500AgroBeeLResponse,
  A4500AgrobeeLCoordinator,
  A4500AgrobeeLInfo,
  A4500AgrobeeLInfoRequest,
  A4500AgrobeeLInfoResponse,
  A4500AgrobeeLModule,
  A4500CommunicationsApi,
  ExpansionConfigurationModuleRequest,
  ExpansionConfigurationModuleResponse,
  ExpansionModuleItemRequest,
  ExpansionModuleItemResponse,
  GetProbeCatalogTargetModuleEnum,
  ModulesApi,
  ProbeCatalogApi,
  ProbeCatalogItem,
  SetAgrobeeL1ModuleInDeviceRequest
} from "@vegga-api-clients/irrigation-control-service";
import { Observable, map, tap } from "rxjs";
import { environment } from "../../../environments/environment";
import { Inject, Injectable } from "../../di";
import { VeggaLoader } from "../../http/veggaLoader";
import { VeggaResponse } from "../../http/veggaResponse";
import { ID, updateCollectionFn } from "../common.facade";
import { VeggaFacade } from "../vegga-facade/vegga-facade";
import { A4500CommunicationModulesTabsEnum } from "./entities/communications.entities";
import { agrobeelMockData, sdi12MockData } from "./mockData";

@Injectable("communicationsFacade")
export class CommunicationsFacade {
  @Inject("veggaFacade") private veggaFacade: VeggaFacade;

  private modulesConfigResponse = new VeggaResponse<ExpansionConfigurationModuleResponse | A4500AgrobeeLInfo>();
  private modulesResponse = new VeggaResponse<ExpansionModuleItemResponse[] | any[]>();
  // Any: Until sdi-12 get is done by backend
  private moduleByIdResponse = new VeggaResponse<ExpansionModuleItemResponse | A4500AgrobeeLModule | any>();
  private modulesApi: ModulesApi;
  private loader = new VeggaLoader();
  private a4500CommunicationsApi: A4500CommunicationsApi;
  private probeCatalogResponse = new VeggaResponse<ProbeCatalogItem[]>();
  private probeCatalogApi: ProbeCatalogApi;

  constructor() {
    this.modulesApi = new ModulesApi();
    this.probeCatalogApi = new ProbeCatalogApi();
    this.a4500CommunicationsApi = new A4500CommunicationsApi();
    this.modulesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.a4500CommunicationsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.probeCatalogApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get globalLoader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }


  get modulesConfig$(): Observable<ExpansionConfigurationModuleResponse | A4500AgrobeeLInfo> {
    return this.modulesConfigResponse.value$;
  }
  // Any because agrobee comes with modules, we mock for the moment
  get modules$(): Observable<ExpansionModuleItemResponse[]> {
    return this.modulesResponse.value$;
  }

  get modules(): ExpansionModuleItemResponse[] {
    return this.modulesResponse.value;
  }
  // Any: Until sdi-12 get is done by backend
  get moduleById$(): Observable<ExpansionModuleItemResponse | A4500AgrobeeLModule | any> {
    return this.moduleByIdResponse.value$;
  }

  get probeCatalog$(): Observable<ProbeCatalogItem[]> {
    return this.probeCatalogResponse.value$;
  }

  get probeCatalog(): ProbeCatalogItem[] {
    return this.probeCatalogResponse.value;
  }

  // get deviceAgrobeeL1Module$(): Observable<A4500AgrobeeLModule> {
  //   return this.deviceAgrobeeL1ModuleResponse.value$;
  // }

  loadModulesConfig(unitId: number, module: string) {
    let req$: Observable<ExpansionConfigurationModuleResponse | A4500AgrobeeLInfo>;

    switch (module) {
      case A4500CommunicationModulesTabsEnum.EXPANSION_MODULES:
        req$ = this.modulesApi.getExpansionConfig({ unitId });
        break;
      case A4500CommunicationModulesTabsEnum.SDI_12:
        // get sdi-12 modules
        req$ = this.modulesApi.getExpansionConfig({ unitId });
        break;
      case A4500CommunicationModulesTabsEnum.AGROBEE_L_1:
        req$ = this.a4500CommunicationsApi.getDeviceAgrobeeL1Info({ id: unitId });
        break;
      case A4500CommunicationModulesTabsEnum.AGROBEE_L_2:
        req$ = this.a4500CommunicationsApi.getDeviceAgrobeeL2Info({ id: unitId });
        break;

      default:
        break;
    }

    const subscription = req$.subscribe({
      next: (res) => {
        this.modulesConfigResponse.set(res);
      },
      error: (err) => {
        this.modulesConfigResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
    this.globalLoader.waitFor(subscription);

  }

  loadModules(unitId: number, module: string) {
    // Any because agrobee comes with modules, we mock for the moment
    let req$: Observable<ExpansionModuleItemResponse[] | any[]>;

    switch (module) {
      case A4500CommunicationModulesTabsEnum.EXPANSION_MODULES:
        req$ = this.modulesApi.getExpansionModules({ unitId });
        break;

      case A4500CommunicationModulesTabsEnum.SDI_12:
        // get sdi-12 modules
        this.modulesResponse.set(sdi12MockData.modules);
        return;
        // req$ = this.a4500CommunicationsApi.getSdi12ModulesInDevice({ id:unitId });
        break;

      case A4500CommunicationModulesTabsEnum.AGROBEE_L_1:
        this.modulesResponse.set(agrobeelMockData.modules);
        return;

      case A4500CommunicationModulesTabsEnum.AGROBEE_L_2:
        this.modulesResponse.set(agrobeelMockData.modules);
        return;

      default:
        break;
    }

    const subscription = req$.subscribe({
      next: (res) => {
        this.modulesResponse.set(res);
      },
      error: (err) => {
        this.modulesResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
    this.globalLoader.waitFor(subscription);
  }

  loadModulesByModuleId(unitId: number, moduleId: number, module: string) {
    // Any: Until sdi-12 get is done by backend
    let req$: Observable<ExpansionModuleItemResponse | A4500AgrobeeLModule | any>;

    switch (module) {
      case A4500CommunicationModulesTabsEnum.EXPANSION_MODULES:
        req$ = this.modulesApi.getExpansionModule({ unitId, moduleId });
        break;

      case A4500CommunicationModulesTabsEnum.SDI_12:
        // get sdi-12 modules
        req$ = this.modulesApi.getExpansionModule({ unitId, moduleId });
        break;

      case A4500CommunicationModulesTabsEnum.AGROBEE_L_1:
        // req$ = this.a4500CommunicationsApi.getDeviceAgrobeeL1Module({ id: unitId, moduleId });
        req$ = moduleId !== 4 ? this.a4500CommunicationsApi.getDeviceAgrobeeL1Module({ id: unitId, moduleId }) : this.modulesConfig$.pipe(map(({ modules }: A4500AgrobeeLInfo) => modules.find(mod => mod.moduleId === moduleId)));
        break;
      case A4500CommunicationModulesTabsEnum.AGROBEE_L_2:
        req$ = this.a4500CommunicationsApi.getDeviceAgrobeeL2Module({ id: unitId, moduleId });
        break;
    }
    const subscription = req$.subscribe({
      next: (res) => {
        this.moduleByIdResponse.set(res);
      },
      error: (err) => {
        // Until sdi-12 get is done by backend
        this.moduleByIdResponse.set({
          id: 0,
          model: 4,
          terrain: "string",
          format: "string",
          density: 0,
          configuration: "MANUAL",
        });
        this.moduleByIdResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  updateExpansionConfig(
    unitId: number,
    expansionConfigurationModuleRequest: ExpansionConfigurationModuleRequest
  ): Observable<ExpansionConfigurationModuleResponse> {
    return this.modulesApi.updateExpansionConfig({ unitId, expansionConfigurationModuleRequest }).pipe(
      tap((config: ExpansionModuleItemResponse) => {
        this.modulesConfigResponse.set(config);
      })
    );
  }

  updateAgrobeelConfig(
    id: number,
    a4500AgrobeeLInfoRequest: A4500AgrobeeLInfoRequest,
    agrobeel: A4500CommunicationModulesTabsEnum
  ): Observable<A4500AgrobeeLInfoResponse> {
    switch (agrobeel) {
      case A4500CommunicationModulesTabsEnum.AGROBEE_L_1:
        return this.a4500CommunicationsApi.updateDeviceAgrobeeL1Coordinator({ id, a4500AgrobeeLInfoRequest })
          .pipe(tap((updatedItem) =>
            this.modulesConfigResponse.set({ modules: (this.modulesConfigResponse.value as A4500AgrobeeLInfo).modules, coordinator: updatedItem as A4500AgroBeeLResponse })));
      case A4500CommunicationModulesTabsEnum.AGROBEE_L_2:
        return this.a4500CommunicationsApi.updateDeviceAgrobeeL2Coordinator({ id, a4500AgrobeeLInfoRequest }).pipe(
          tap((config: A4500AgrobeeLInfoResponse) => {
            this.modulesConfigResponse.set({
              ...this.modulesConfigResponse.value,
              coordinator: config as unknown as A4500AgrobeeLCoordinator,
            });
          })
        );
    }
  }

  updateExpansionModuleItem(
    unitId: number,
    moduleId: number,
    expansionModuleItemRequest: ExpansionModuleItemRequest
  ): Observable<ExpansionConfigurationModuleResponse> {
    return this.modulesApi
      .updateExpansionModuleItem({ unitId, moduleId, expansionModuleItemRequest })
      .pipe(updateCollectionFn(this.modulesResponse, moduleId, ID));
  }

  updateAgrobeelModuleItem(
    unitId: number,
    moduleId: number,
    agroobel: A4500CommunicationModulesTabsEnum,
    agrobeeL1ModuleItemRequest: A4500AgroBeeLRequest
  ): Observable<A4500AgroBeeLResponse> {
    switch (agroobel) {
      case A4500CommunicationModulesTabsEnum.AGROBEE_L_1:
        return this.a4500CommunicationsApi
          .updateAgrobeeL1ModuleInDevice({
            id: unitId,
            moduleId,
            a4500AgroBeeLRequest: agrobeeL1ModuleItemRequest,
          })
          .pipe(tap((updatedItem) => {
            const updatedItemIndex = this.modulesResponse.value.findIndex((module: A4500AgrobeeLModule) => module.moduleId === moduleId);
            const modules = this.modulesConfigResponse.value as A4500AgrobeeLInfo;
            modules.modules[updatedItemIndex].model = updatedItem.model.toString();
            this.modulesResponse.set([...modules.modules]);
          }));
      case A4500CommunicationModulesTabsEnum.AGROBEE_L_2:
        return this.a4500CommunicationsApi
          .updateAgrobeeL2ModuleInDevice({
            id: unitId,
            moduleId,
            a4500AgroBeeLRequest: agrobeeL1ModuleItemRequest,
          })
          .pipe(tap((updatedItem) => {
            const updatedItemIndex = this.modulesResponse.value.findIndex((module: A4500AgrobeeLModule) => module.moduleId === moduleId);
            const modules = this.modulesConfigResponse.value as A4500AgrobeeLInfo;
            modules.modules[updatedItemIndex].model = updatedItem.model.toString();
            this.modulesResponse.set([...modules.modules]);
          }));

      default:
        break;
    }
  }


  setAgrobeeL1ModuleInDevice({ id, moduleId, a4500AgrobeeLModuleConfigurationRequest }: SetAgrobeeL1ModuleInDeviceRequest) {
    return this.a4500CommunicationsApi.setAgrobeeL1ModuleInDevice(
      {
        id,
        moduleId,
        a4500AgrobeeLModuleConfigurationRequest
      });
  }

  addSdi12ModuleInDevice({ deviceId, moduleId, configuration, probeId }) {
    return this.a4500CommunicationsApi.addSdi12ModuleInDevice(
      {
        id: deviceId,
        moduleId,
        a4500Sdi12ModuleConfigurationRequest: {
          configuration,
          probeId
        }
      });

  }

  loadProbeCatalog(targetModule?: GetProbeCatalogTargetModuleEnum) {
    const req$ = this.probeCatalogApi.getProbeCatalog({ targetModule: GetProbeCatalogTargetModuleEnum.AGROBEEL_SDI12 });
    const subscription = req$.subscribe({
      next: (res) => {
        this.probeCatalogResponse.set(res);
      },
      error: (err) => {
        this.probeCatalogResponse.setError(err, {});
      },
    });

    this.veggaFacade.loader.waitFor(subscription);
  }

  reset(): void {
    this.moduleByIdResponse.clear();
  }

  clear(): void {
    this.modulesResponse.clear();
  }
}
